
























































































































































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import SelectSupplier from "@/components/custom/select/SelectSupplier.vue";
import { debounceProcess } from "@/helpers/debounce";
import {
  Row,
  useBlob,
  useDate,
  useFindMasterType,
  useLocalFilter,
  useMapMasterTypeToOption,
  useReportPayableMutation,
} from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { ReportPayableMutationLineDTO } from "@/models/interface/ReportPayableMutation.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { LabelInValue } from "@/types";
import { AddressDataDto, ListContactDataDto } from "@interface/contact-data";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormValues = {
  invoiceType: string | null;
  status: string | null;
  branchId: string;
  supplier: LabelInValue | undefined;
  currencyId: string;
  currencyCode: string;
  date: Moment[] | null;
  paymentDate: Moment[] | null;
  invoiceNumber: string;
  invoiceSupplierNumber: string;
};
type TableRow = Row<ReportPayableMutationLineDTO>;

@Component({
  components: {
    SelectBranch,
    SelectSupplier,
    SelectCurrency,
  },
})
export default class PayableMutationReport extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  useLocalFilter = useLocalFilter;
  @Ref("debtCardForm") readonly debtCardForm!: FormModel;

  formModel: FormValues = {
    status: "",
    invoiceType: "",
    branchId: "",
    supplier: undefined,
    currencyId: "",
    currencyCode: "",
    date: [],
    paymentDate: [],
    invoiceNumber: "",
    invoiceSupplierNumber: "",
  };

  formRules = {
    branchId: [],
    supplier: [
      { required: false, message: this.$t("lbl_validation_required_error") },
    ],
    currencyId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    date: [
      { required: false, message: this.$t("lbl_validation_required_error") },
    ],
    invoiceNumber: [],
    invoiceSupplierNumber: [],
    paymentTo: [],
    paymentFrom: [],
    status: [],
    invoiceType: [],
  };

  loading = {
    download: false,
    invoice: false,
    invoiceSupplier: false,
    find: false,
    status: false,
    invoiceType: false,
  };

  vmInfo = {
    branchName: "",
    supplierName: "",
    supplierBillToAddress: "",
    totalInvoiceOutstanding: 0,
    endingBalance: 0,
    beginningBalance: 0,
    reset(): void {
      this.branchName = "";
      this.supplierName = "";
      this.supplierBillToAddress = "";
      this.totalInvoiceOutstanding = 0;
      this.endingBalance = 0;
      this.beginningBalance = 0;
    },
  };

  optInvoiceNumbers: Option[] = [];
  optInvoiceSupplierNumbers: Option[] = [];
  optStatus: Option[] = [];
  optInvoiceType: Option[] = [];
  dataSource: TableRow[] = [];

  columns = [
    {
      title: this.$t("lbl_supplier_number"),
      dataIndex: "supplierNumber",
      key: "customerCode",
      scopedSlots: { customRender: "nullable" },
      fixed: "left",
      width: 150,
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      key: "customerName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_supplier_number"),
      dataIndex: "invoiceSupplierNumber",
      key: "invoiceSupplierNumber",
      width: "250px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      key: "invoiceNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_tax_invoice"),
      dataIndex: "taxInvoiceNumber",
      key: "taxInvoice",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_rate"),
      dataIndex: "currencyRate",
      key: "rate",
      width: "100px",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_top"),
      dataIndex: "termOfPayment",
      key: "top",
      width: "100px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_due_date"),
      dataIndex: "dueDate",
      key: "dueDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "dpp",
      key: "dpp",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_ppn"),
      dataIndex: "ppn",
      key: "ppn",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_payment_number"),
      dataIndex: "apNumber",
      key: "paymentNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_transaction_date"),
      dataIndex: "apDate",
      key: "transactionDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_write_off_amount"),
      dataIndex: "writeOffAmount",
      key: "writeOffAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_additional_amount"),
      dataIndex: "additionalAmount",
      key: "additionalAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_invoice_balance"),
      dataIndex: "invoiceBalance",
      key: "invoiceBalance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_payment_value"),
      dataIndex: "apPaidAmount",
      key: "paymentValue",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_pph"),
      dataIndex: "pphAmount",
      key: "pphAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_ending_balance"),
      dataIndex: "endingBalance",
      key: "endingBalance",
      scopedSlots: { customRender: "currency" },
    },
  ];

  created(): void {
    this.onSearchInvoice = debounceProcess(this.onSearchInvoice);
    this.onSearchSupplierInvoice = debounceProcess(
      this.onSearchSupplierInvoice
    );

    this.getInvoiceSupplierNumber(new RequestQueryParams());
    this.getInvoiceNumber(new RequestQueryParams());
    this.fetchInvoiceStatus();
    this.fetchInvoiceType();
  }

  fetchInvoiceType(): void {
    this.loading.invoiceType = true;
    useFindMasterType("INVOICE_AP_SOURCE")
      .then(res => {
        this.optInvoiceType = useMapMasterTypeToOption(res);
      })
      .finally(() => {
        this.loading.invoiceType = false;
      });
  }

  fetchInvoiceStatus(): void {
    this.loading.status = true;
    useFindMasterType("INVOICE_AP_STATUS")
      .then(res => {
        const statuses = [
          INVOICE_AP_STATUS.UNPAID,
          INVOICE_AP_STATUS.PARTIAL_PAID,
          INVOICE_AP_STATUS.FULLY_PAID,
        ];
        this.optStatus = useMapMasterTypeToOption(res).filter(e =>
          statuses.includes(e.label as INVOICE_AP_STATUS)
        );
      })
      .finally(() => {
        this.loading.status = false;
      });
  }

  onChangeSupplier(option: Option<ListContactDataDto> | undefined): void {
    this.vmInfo.supplierName = option?.meta?.fullName || "";
    const address: AddressDataDto | undefined =
      option?.meta?.addressDataList.find(item => item.primaryBillTo);
    this.vmInfo.supplierBillToAddress = address?.address || "";
  }

  onChangeCurrency(option: Option<DataMasterCurrency> | undefined): void {
    this.formModel.currencyCode = option?.meta?.currencyCode || "";
  }

  getInvoiceNumber(params: RequestQueryParamsModel): void {
    const { findAllInvoice } = useReportPayableMutation();
    this.loading.invoice = true;
    findAllInvoice(params)
      .then(res => {
        this.optInvoiceNumbers = res.data.map<Option>(item => ({
          value: item,
          label: item,
          key: item,
        }));
      })
      .finally(() => {
        this.loading.invoice = false;
      });
  }

  onSearchInvoice(value = ""): void {
    const params = new RequestQueryParams();
    if (value) {
      params.invoiceNumber = value;
    }
    this.getInvoiceNumber(params);
  }

  onSearchSupplierInvoice(value = ""): void {
    const params = new RequestQueryParams();
    if (value) {
      params.invoiceNumber = value;
    }
    this.getInvoiceSupplierNumber(params);
  }

  getInvoiceSupplierNumber(params: RequestQueryParamsModel): void {
    const { findAllInvoiceSupplier } = useReportPayableMutation();
    this.loading.invoiceSupplier = true;
    findAllInvoiceSupplier(params)
      .then(response => {
        this.optInvoiceSupplierNumbers = response.data
          .filter(item => item !== "" && item !== "-")
          .map<Option>(item => ({
            value: item,
            key: item,
            label: item || "-",
          }));
      })
      .finally(() => (this.loading.invoiceSupplier = false));
  }

  resetForm(): void {
    this.debtCardForm.resetFields();
    this.vmInfo.reset();
  }

  onSubmit(): void {
    this.debtCardForm.validate((valid: boolean) => {
      if (!valid) return;
      this.findData();
    });
  }

  findData(): void {
    const { buildSearchList, findAll } = useReportPayableMutation();
    const params = new RequestQueryParams();
    params.search = buildSearchList(this.formModel);

    if (this.formModel.supplier) {
      params.supplierId = this.formModel.supplier.key;
    }

    const { toStartDay, toEndDay } = useDate();
    if (this.formModel.date && this.formModel.date.length > 0) {
      const [start, end] = this.formModel.date;
      params.invoiceDateFrom = toStartDay(start).format();
      params.invoiceDateTo = toEndDay(end).format();
    }

    if (this.formModel.paymentDate && this.formModel.paymentDate.length > 0) {
      const [start, end] = this.formModel.paymentDate;
      params.paymentDateFrom = toStartDay(start).format();
      params.paymentDateTo = toEndDay(end).format();
    }

    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataSource = res.payableMutationLines.map((item, i) => ({
          ...item,
          key: i,
        }));
        this.vmInfo.totalInvoiceOutstanding = res.endingBalance;
      })
      .finally(() => (this.loading.find = false));
  }

  async handleDownload(): Promise<void> {
    const { toDownload } = useBlob();
    const { buildSearchList, download } = useReportPayableMutation();
    const { toStartDay, toEndDay } = useDate();
    const params = new RequestQueryParams();
    const company =
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA";

    try {
      this.loading.download = true;
      const { supplierName } = this.vmInfo;
      const {
        currencyCode,
        date: invoiceDate,
        invoiceNumber,
        invoiceSupplierNumber,
        paymentDate,
        status,
        invoiceType,
        supplier,
      } = this.formModel;

      const branch = this.vmInfo.branchName || "ALL";

      if (supplier) {
        params.supplierId = supplier.key;
      }

      let invoiceDateFrom = "ALL";
      let invoiceDateTo = "ALL";
      if (invoiceDate && invoiceDate.length > 0) {
        const [start, end] = invoiceDate;
        invoiceDateFrom = start.format(DEFAULT_DATE_FORMAT);
        invoiceDateTo = end.format(DEFAULT_DATE_FORMAT);
        params.invoiceDateFrom = toStartDay(start).format();
        params.invoiceDateTo = toEndDay(end).format();
      }

      let paymentDateFrom = "ALL";
      let paymentDateTo = "ALL";
      if (paymentDate && paymentDate.length > 0) {
        const [start, end] = paymentDate;
        paymentDateFrom = start.format(DEFAULT_DATE_FORMAT);
        paymentDateTo = end.format(DEFAULT_DATE_FORMAT);
        params.paymentDateFrom = toStartDay(start).format();
        params.paymentDateTo = toEndDay(end).format();
      }

      const q: Array<string> = [
        company,
        branch,
        supplierName.replaceAll(", ", " ") || "ALL",
        currencyCode || "ALL",
        invoiceDateFrom,
        invoiceDateTo,
        status || "ALL",
        invoiceType || "ALL",
        paymentDateFrom,
        paymentDateTo,
        invoiceNumber || "ALL",
        invoiceSupplierNumber || "ALL",
      ];

      params.params = q.join(",");
      params.search = buildSearchList(this.formModel);

      const blob = await download(params);
      this.showNotifSuccess("notif_download_report_success");
      toDownload(blob, "payable_mutation_report.xlsx");
    } catch (error) {
      this.showNotifError("notif_download_error");
    } finally {
      this.loading.download = false;
    }
  }

  onChangeBranch(e: Option<DataWarehouseBranch> | undefined): void {
    this.vmInfo.branchName = e?.meta?.name || "";
  }
}
